import './payments_accepted.scss';

const THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	$onReady({
		rp$,
		window,
		label: 'payments_accepted',
		fn: function () {},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
